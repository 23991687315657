<template>
  <div>
    <v-dialog
      v-model="open"
      transition="scale-transition"
      persistent
      scrollable
      max-width="750px"
    >
      <v-card flat>
        <v-card-title>
          {{ $t("permission.add_student") }}
          <div style="position: absolute; right: 25px;">
            <v-icon :disabled="loading" @click="closeDialog">mdi-close</v-icon>
          </div>
        </v-card-title>
        <v-card-text
          :style="stepPosition === 1 ? 'height: 600px' : '400px'"
          class="mt-2"
          style="overflow-y: auto;"
        >
          <v-stepper v-model="stepPosition" class="elevation-0">
            <hr />
            <v-stepper-header class="elevation-0">
              <v-stepper-step :complete="stepPosition > 1" step="1">
                <span>{{ $t("permission.select_students") }}</span>
              </v-stepper-step>
              <v-divider />
              <v-stepper-step :complete="stepPosition > 2" step="2">
                <span>{{ secondStep }}</span>
              </v-stepper-step>
            </v-stepper-header>
            <hr />

            <v-stepper-items>
              <v-stepper-content step="1" class="stepper-content">
                <v-row class="pa-2">
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="idGrade"
                      :items="listGrade"
                      :label="$t('schedule.choose_grade')"
                      item-value="id"
                      item-text="grade"
                      outlined
                      hide-details
                      dense
                      @change="changeGrade"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="idClass"
                      :items="listClass"
                      :label="$t('academic.schedule_data.type_of_class')"
                      :disabled="loadingClass || !idGrade"
                      :loading="loadingClass"
                      item-value="id"
                      item-text="name"
                      outlined
                      hide-details
                      dense
                      @change="getStudent"
                    />
                  </v-col>
                  <v-col cols="12" class="my-3">
                    <v-text-field
                      v-model="table.search"
                      :label="$t('app.search')"
                      clearable
                      outlined
                      dense
                      hide-details
                      prepend-inner-icon="mdi-magnify"
                      @keydown="searchHandler()"
                      @keyup.enter="searchHandler()"
                      @click:clear="searchHandler()"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-data-table
                      v-model="dataStudents"
                      :headers="tableHeaders"
                      :items="dataTable.data"
                      :loading="table.loading"
                      item-key="person"
                      show-select
                      disable-pagination
                      hide-default-footer
                      class="elevation-0"
                    >
                      <template #item.number="{item}">
                        {{
                          dataTable.data.indexOf(item) + dataTable.from - 1 + 1
                        }}
                      </template>
                    </v-data-table>
                    <Pagination
                      :length="dataTable.last_page"
                      :total="dataTable.total"
                      :current-page="table.page"
                      :limit="table.limit"
                      :handler="paginateChange"
                      class="ma-3"
                    />
                  </v-col>
                </v-row>
              </v-stepper-content>

              <v-stepper-content step="2" class="stepper-content">
                <FormPermission
                  :bodyRequest="bodyRequest"
                  :listPermission="listMaster"
                  :loading="loadingMaster"
                  :checkValidate="validateForm"
                  :reset="resetForm"
                  class="pa-2"
                />
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-divider class="my-3" />
        <v-card-actions>
          <v-row no-gutters justify="end">
            <div v-if="stepPosition === 1" class="d-flex">
              <v-btn
                outlined
                dark
                color="primary"
                class="mr-2"
                @click="closeDialog"
              >
                {{ $t("app.cancel") }}
              </v-btn>
              <v-btn
                color="gradient-primary"
                depressed
                dark
                @click="nextStep(1)"
              >
                {{ $t("app.next") }}
              </v-btn>
            </div>
            <div v-else style="min-width: 100%">
              <div style="float: right">
                <v-btn
                  :disabled="loading"
                  :dark="!loading"
                  outlined
                  color="primary"
                  class="mr-2"
                  @click="prevStep(2)"
                >
                  {{ $t("app.back") }}
                </v-btn>
                <v-btn
                  :loading="loading"
                  color="gradient-primary"
                  depressed
                  dark
                  @click="save()"
                >
                  {{ $t("app.save") }}
                </v-btn>
              </div>
            </div>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { get_class_list } from "@/api/admin/schoolClass";
import { studentsList } from "@/api/admin/master/student";
import { getListMaster, addPermission } from "@/api/admin/absent/permission";
import { uploadFile } from "@/api/admin/admin";

let typingTimer;
const doneTypingInterval = 750;

export default {
  props: {
    open: Boolean,
    idSchoolYear: Number,
    listGrade: Array
  },
  components: {
    Pagination: () => import("@/components/Pagination"),
    FormPermission: () => import("./components/FormPermission")
  },
  computed: {
    secondStep() {
      const studentTotal = this.dataStudents.length;
      if (studentTotal === 0) return "";
      else return `${studentTotal} ${this.$i18n.t("permission.second_step")}`;
    }
  },
  watch: {
    open(value) {
      if (value) {
        this.resetForm = false;
        this.masterPermission();
      }
    }
  },
  data() {
    return {
      validateForm: false,
      listMaster: [],
      loadingMaster: false,
      tableHeaders: [
        {
          text: "#",
          value: "number",
          sortable: false
        },
        { text: this.$i18n.t("app.name"), value: "name", sortable: false },
        { text: this.$i18n.t("app.nis"), value: "nis", sortable: false },
        {
          text: "",
          value: "data-table-select",
          width: 100
        }
      ],
      resetForm: false,
      idClass: 0,
      loadingClass: false,
      idGrade: 0,
      listClass: [],
      stepPosition: 1,
      steps: 2,
      dataStudents: [],
      loading: false,
      table: {
        loading: false,
        page: 1,
        limit: 10,
        search: ""
      },
      dataTable: { data: [] },
      bodyRequest: {
        person: [],
        leave: 0,
        type: "student",
        date_in: "",
        date_out: "",
        file: "",
        temp_file: null,
        desc: ""
      },
      typeList:
        this.$store.getters.g_role_type === "TEACHER" ? "HOMEROOM_TEACHER" : ""
    };
  },
  methods: {
    searchHandler() {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.table.page = 1;
        this.getStudent();
      }, doneTypingInterval);
    },
    async uploadImage() {
      let result = "";
      let bodyImage = new FormData();
      bodyImage.set("path", "permission/student");
      bodyImage.set("module", "PERMISSION");
      bodyImage.append("file", this.bodyRequest.temp_file);
      if (this.bodyRequest.temp_file) {
        try {
          const response = await uploadFile(bodyImage);
          if (response.data.code) {
            result = response.data.data.path;
          } else {
            this.snackBar(false, response.data.message);
          }
        } catch (error) {
          this.snackBar(false, error);
        }
      }
      return result;
    },
    async masterPermission() {
      this.loadingMaster = true;
      try {
        const response = await getListMaster({
          prefix: "student"
        });
        if (response.data.code) {
          this.listMaster = response.data.data;
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, error);
      }
      this.loadingMaster = false;
    },
    paginateChange(page, limit) {
      this.table.page = page;
      this.table.limit = limit;
      this.getStudent();
    },
    async getStudent() {
      this.loading = true;
      this.table.loading = true;
      this.dataStudents = [];
      try {
        const filter = {
          "class_students.class": [this.idClass],
          "class_masters.id": [this.idGrade],
          "class_students.school_year": [this.idSchoolYear]
        };
        const response = await studentsList({
          filter: { ...filter },
          ...this.table
        });
        if (response.data.code) {
          this.dataTable = response.data.data;
        } else {
          this.snackBar(false, response.data.message);
        }
      } catch (error) {
        this.snackBar(false, error);
      }
      this.table.loading = false;
      this.loading = false;
    },
    changeGrade(grade) {
      this.loadingClass = true;
      get_class_list(
        {
          grade: [grade],
          school_year: [this.idSchoolYear],
          type: this.typeList
        },
        true
      )
        .then(res => {
          if (res.data.code) {
            let r = res.data.data;
            this.listClass = r;
          } else {
            this.snackBar(false, res.data.message);
          }
          this.loadingClass = false;
          this.dataTable = { data: [] };
        })
        .catch(err => {
          this.loadingClass = false;
          this.snackBar(false, err);
        });
    },
    async save() {
      if (
        this.bodyRequest.leave &&
        this.bodyRequest.date_in &&
        this.bodyRequest.date_out &&
        this.bodyRequest.desc
      ) {
        this.loading = true;
        try {
          this.bodyRequest.person = this.dataStudents.map(item => item.person);
          this.bodyRequest.file = await this.uploadImage();
          const response = await addPermission(this.bodyRequest);
          if (response.data.code) {
            this.snackBar(true, response.data.message);
            if (response.data.data && response.data.data.length > 0) {
              response.data.data.map(r => {
                this.$socket.emit("serverNotif", {
                  recipient: r.recipient,
                  data: r
                });
              });
            }
            this.closeDialog();
          } else {
            this.snackBar(false, response.data.message);
          }
        } catch (error) {
          this.snackBar(false, error);
        }
        this.loading = false;
      } else {
        this.validateForm = true;
      }
    },
    prevStep(n) {
      if (n === 1) {
        this.stepPosition = 1;
      } else {
        this.stepPosition = n - 1;
      }
      this.$vuetify.goTo(150);
    },
    nextStep(n) {
      if (n === this.steps) {
        this.stepPosition = 1;
      } else {
        if (this.dataStudents.length > 0) {
          this.stepPosition = n + 1;
        } else {
          this.snackBar(false, this.$i18n.t("permission.msg_minimal_students"));
        }
      }
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    closeDialog() {
      this.reset();
      this.$emit("close");
    },
    reset() {
      this.idClass = 0;
      this.idGrade = 0;
      this.dataTable = { data: [] };
      this.resetForm = true;
      this.stepPosition = 1;
      this.dataStudents = [];
      this.bodyRequest = {
        person: [],
        leave: 0,
        type: "student",
        date_in: "",
        date_out: "",
        file: "",
        temp_file: null,
        desc: ""
      };
    }
  }
};
</script>
